import Cookies from 'js-cookie';

/** User auth data */
const USER_TOKEN_COOKIE = 'userToken';
const USER_DATA_COOKIE = 'userData';

export const setUserCookie = user => {
  Cookies.set(USER_TOKEN_COOKIE, user.authToken, {
    expires: 1, secure: process.env.NODE_ENV === "production"
  });

  Cookies.set(USER_DATA_COOKIE, JSON.stringify(user.user), {
    expires: 1, secure: process.env.NODE_ENV === "production"
  });
};

export const getUserCookie = (ctx = null) => {
  const userToken = Cookies.get(USER_TOKEN_COOKIE);
  const userData = Cookies.get(USER_DATA_COOKIE);
  if (!userData || !userToken) {
    return null;
  }

  return {
    authToken: userToken,
    user: JSON.parse(userData)
  }
};

export const removeUserCookie = () => {
  Cookies.remove(USER_TOKEN_COOKIE);
  Cookies.remove(USER_DATA_COOKIE);
};
