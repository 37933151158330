import { del, get, post, postFormData } from "./api_helper"
import * as url from "./url_helper"

// Contacts
const parseCsv = data => postFormData(url.PARSE_CSV, data)
const getFields = params => get(url.GET_FIELDS, params)
const importData = data => postFormData(url.IMPORT_CONTACTS_DATA, data)

// Events
const importEventsData = data => postFormData(url.IMPORT_EVENTS_DATA, data)

// Billable Items
const importBillableItemsData = data => postFormData(url.IMPORT_BILLABLE_ITEMS_DATA, data)

// CC Tokens
const importCCTokensData = data => postFormData(url.IMPORT_CC_TOKENS_DATA, data)

// Auto Renew List
const importAutoRenewData = data => postFormData(url.IMPORT_AUTO_RENEW_DATA, data)

// Bio
const importBioData = data => post(url.IMPORT_CONTACTS_BIO_DATA, data)

// Languages
const importLanguagesData = data => post(url.IMPORT_CONTACTS_LANGUAGES_DATA, data)

// Validate
const validateImportData = data => post(url.IMPORT_CONTACTS_VALIDATE_DATA, data)

// Accreditations
const importAccreditationsData = data => post(url.IMPORT_CONTACTS_ACCREDITATIONS_DATA, data)

// Areas
const importAreasData = data => post(url.IMPORT_CONTACTS_AREAS_DATA, data)

// Broker of record
const importBrokerageRolesData = data => postFormData(url.IMPORT_BROKERAGE_ROLES_DATA, data)

// M1 Sync
const importM1SyncStatusData = data => postFormData(url.IMPORT_M1_SYNC_DATA, data)

export { parseCsv, getFields, importData, importEventsData, importBillableItemsData, importCCTokensData, importAutoRenewData, importBioData, importLanguagesData, validateImportData, importAccreditationsData, importAreasData, importBrokerageRolesData, importM1SyncStatusData }
