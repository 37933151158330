import PropTypes from "prop-types"
import React, { Suspense, useEffect } from "react"
import { Switch } from "react-router-dom"
import { connect } from "react-redux"
import LoadingBar from "react-redux-loading-bar"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// actions
import { accessCheck } from "./store/auth/login/actions"

// react sortable tree
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app

const App = props => {  
  if (!props.auth.isAccessChecked) {
    return <></>
  }

  if (!props.auth.isAccessible) {
    return <h1>Not Authorized!</h1>
  }

  return (
    <React.Fragment>
      <LoadingBar className="loadingBar" />
      <Suspense fallback={<div></div>}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
              isUserLoggedIn={props.auth.is_user_logged_in}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              isUserLoggedIn={props.auth.is_user_logged_in}
            />
          ))}
        </Switch>
      </Suspense>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  auth: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    auth: state.Login,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkAccessAction: dispatch(accessCheck()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
