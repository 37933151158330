import { del, get, post, put, postFormData } from "./api_helper"
import * as url from "./url_helper"

// Accounts
const getAccounts = (params = null) => get(url.GET_ACCOUNT, params)
const createAccount = data => post(url.CREATE_ACCOUNT, data)
const assignAccount = data => post(url.ASSIGN_ACCOUNT_TO_USER, data)
const deleteAccount = data => del(url.DELETE_ACCOUNT, data)
const updateTimezoneAccount = data => post(url.UPDATE_TIMEZONE_ACCOUNT, data)
const updateMlsViewCredentialsConfig = data => postFormData(url.UPDATE_ACCOUNT_MLS_CONFIG_CREDENTIALS, data)
const updateMlsViewStatus = data => post(url.UPDATE_ACCOUNT_MLS_CONFIG_STATUS, data)
const getRecurringCountsLog = params => get(url.ACCOUNT_ACTIVE_RECURRING_COUNT, params)
const getRecurringCountsLogDownload = (params, config = {}) => get(url.ACCOUNT_ACTIVE_RECURRING_COUNT_DOWNLOAD, params, config)
const getCustomFieldsList = (account_id) => get(url.GET_CUSTOM_FIELDS_LIST + account_id);
const updateAccountLicenseConfig = data => post(url.UPDATE_LICENSE_CONFIG, data)
const getLicenseCronLogs = () => get(url.GET_LICENSE_CRON_LOGS)

// DB Updations
const getUpdations = () => get(url.GET_DB_UPDATIONS)
const addNewUpdation = updation => post(url.ADD_NEW_DB_UPDATION, updation)
const deployUpdation = updation =>
  post(url.DEPLOY_DB_UPDATION_TO_PROD, updation)
const deployUpdationTest = updation =>
  post(url.DEPLOY_DB_UPDATION_TO_TEST, updation)
const runSeederAccDB = data => post(url.ACC_DB_RUN_SEEDER, data)

// Onboarding data
const getOBTitles = () => get(url.GET_OB_TITLES)
const getOBBrokerages = () => get(url.GET_OB_BROKERAGES)
const getOBAccrs = () => get(url.GET_OB_ACCRS)
const getOBBoards = () => get(url.GET_OB_BOARDS)
const getOBServiceTypes = () => get(url.GET_OB_SERVICE_TYPES)
const approveOnboarding = data => post(url.APPROVE_OB, data)

// SG
const createMerchantSG = data => post(url.CREATE_SG_ORG_USER, data)
const connectPaymentGatewaySG = data =>
  post(url.CONNECT_PAYMENT_GATEWAY_SG, data)
const updateSGPaymentGatewaySettings = data => post(url.UPDATE_SG_PAYMENT_GATEWAY_SETTINGS, data)

// Account DB: Profiles
const getProfiles = params => get(url.GET_PROFILES, params)

// Account DB: Contacts
const deleteContacts = data => del(url.DELETE_CONTACTS, data)

// Account DB: Custom Fields
const createCustomField = data => post(url.CREATE_CUSTOM_FIELD, data)

// Account Config
const getAccountConfig = (params = null) => get(url.GET_ACCOUNT_CONFIG, params)
const updateAccountConfig = data => post(url.UPDATE_ACCOUNT_CONFIG, data)
const updateAccountSsoConfig = data => post(url.UPDATE_ACCOUNT_SSO_CONFIG, data)
const getMemberPortalColumns = (params = null) => get(url.GET_MEMBER_PORTAL_COLUMNS, params)
const saveMemberPortalColumnsMapping = data => post(url.GET_MEMBER_PORTAL_COLUMNS, data)

const segmentsOperators = (params = null) => get(url.SEGMENT_OPERATORS_LIST, params);

// Quickbooks
const getQuickbooksConfig = (params = null) => get(url.GET_QB_CONFIG, params)
const saveQuickbooksConfig = (data = null) => post(url.GET_QB_CONFIG, data);
const getQBEntities = (params = null) => get(url.GET_QB_ENTITIES, params);
const exportQBEntities = (data = null, config = {}) => post(url.EXPORT_QB_ENTITIES, data, config);

// Account Integration
const saveIntegrationSettings = (data = null) => post(url.SAVE_INTEGRATION_SETTINGS, data);

// M1 Sync
const getM1SyncLogs = (params = null) => get(url.GET_M1_SYNC_LOGS, params);
const getMemberOfficeM1 = (data = null) => post(url.GET_MEMBER_OFFICE_M1, data);
const getDownloadFileByID = (data = null, config = {}) => post(url.GET_DOWNLOAD_FILE_BY_ID, data, config);

// Update Association AOR ID
const updateAssociationAorId = (data = null) => post(url.UPDATE_ASSOCIATION_AOR_ID, data);

export {
  updateAccountSsoConfig,
  updateAccountLicenseConfig,
  updateMlsViewStatus,
  updateMlsViewCredentialsConfig,
  updateTimezoneAccount,
  getAccounts,
  createAccount,
  assignAccount,
  deleteAccount,
  getUpdations,
  addNewUpdation,
  deployUpdation,
  deployUpdationTest,
  getOBTitles,
  getOBBrokerages,
  getOBAccrs,
  getOBBoards,
  getOBServiceTypes,
  approveOnboarding,
  createMerchantSG,
  runSeederAccDB,
  connectPaymentGatewaySG,
  getProfiles,
  deleteContacts,
  createCustomField,
  getAccountConfig,
  updateAccountConfig,
  getRecurringCountsLog,
  getRecurringCountsLogDownload,
  segmentsOperators,
  getCustomFieldsList,
  getLicenseCronLogs,
  getMemberPortalColumns,
  saveMemberPortalColumnsMapping,
  getQBEntities,
  exportQBEntities,
  getQuickbooksConfig,
  saveQuickbooksConfig,
  saveIntegrationSettings,
  updateSGPaymentGatewaySettings,
  getM1SyncLogs,
  getMemberOfficeM1,
  getDownloadFileByID,
  updateAssociationAorId,
}
