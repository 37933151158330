import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';

const useCheckPermission = requiredPermission => {
  const history = useHistory();

  const authState = useSelector(state => state.Login?.user);
  const isAdmin = authState?.user?.id === 1;
  const userPermissions = authState?.user?.permissions || {}

  useEffect(() => {
    if (userPermissions && !userPermissions[requiredPermission] && !isAdmin) {
      history.replace('/dashboard');
    }
  }, [userPermissions, requiredPermission, history]);
};

export default useCheckPermission;
