import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// get
const getAccr = () => get(url.GET_ACCR)

// add/update
const createUpdateAccr = data => post(url.CREATE_UPDATE_ACCR, data)

// delete accr
const deleteAccr = data => del(url.DELETE_ACCR, data)

export { getAccr, createUpdateAccr, deleteAccr }
