import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// get
const getLanguage = () => get(url.GET_LANGUAGE)

// add/update
const createUpdateLanguage = data => post(url.CREATE_UPDATE_LANGUAGE, data)

// delete Language
const deleteLanguage = data => del(url.DELETE_LANGUAGE, data)

export { getLanguage, createUpdateLanguage, deleteLanguage }
