import React, { useState } from 'react';
import ConfirmationModal from './ConfirmationModal';

function TextEditMenu({ deleteText }) {
    const [openDeleteModal, setDeleteModal] = useState(false)
;
  
  return (
    <div className='text-edit-button'>
        <button type="button" onClick={() => { setDeleteModal(true) }} className="action-button delete-button ab-icons">trash</button>
        <ConfirmationModal title="Delete Video" onOk={async () => {
          await deleteText()
          setDeleteModal(false)
        }} modal={openDeleteModal} closeModal={() => setDeleteModal(false)} />
    </div>
    
  );
}

export default TextEditMenu;
