import React, { useState } from 'react';
import { useEffect } from 'react';
import E from 'react-script';
import { Dropdown, DropdownMenu } from 'reactstrap';
import ConfirmationModal from './ConfirmationModal';

function VideoEditMenu({ setVideoMenu, deleteVideo, hideEdit, styles, setStyles }) {
    const [menu, setMenu] = useState(false)
    const [openDeleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        setVideoMenu(menu)
    }, [menu]);
  
  return (
    <div>
        {hideEdit ? null : <Dropdown isOpen={menu} toggle={() => setMenu(false)} >
            <button type="button" onClick={() => { setMenu(true) }} className="action-button ab-icons">edit</button>
            <DropdownMenu end style={{ width: 300 }}>
                <p style={{fontWeight: 600, fontSize: 16, borderBottom: '1px solid #eee', padding: '0 10px 5px 10px'}}>Video Styles</p>
                <div style={{padding: '5px 15px 25px 15px'}}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='m-0'>Height</p>
                        <div className='d-flex'>
                        <input style={{width: 100}} type="number" value={styles.height} onChange={(e) => setStyles({
                            ...styles, 
                            height: e.target.value
                        })} />
                        <span className='ps-2'>px</span>
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown> }
        
        <button style={{marginTop: hideEdit ? 0 : ''}} type="button" onClick={() => { setDeleteModal(true) }} className="delete-button action-button ab-icons">trash</button>
        <ConfirmationModal title="Delete Video" onOk={async () => {
              await deleteVideo()
              setDeleteModal(false)
        }} modal={openDeleteModal} closeModal={() => setDeleteModal(false)} />
    </div>
    
  );
}

export default VideoEditMenu;
