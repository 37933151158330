import React from "react"
import PropTypes from "prop-types"

const Preloader = props => {
  return (
    <div
      id="preloader-custom"
      className={!props.showLoader ? "d-none" : undefined}
    >
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
        </div>
      </div>
    </div>
  )
}

Preloader.defaultProps = {
  showLoader: false,
}
Preloader.propTypes = {
  showLoader: PropTypes.bool,
}

export default Preloader
