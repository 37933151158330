import { post } from "./api_helper";
import * as url from "./url_helper"

const importNotesData = (data) => post(url.IMPORT_NOTES, data, { baseURL: process.env.REACT_APP_CHAT_API_URL })
const deleteNotesData = (data) => post(url.DELETE_NOTES, data, { baseURL: process.env.REACT_APP_CHAT_API_URL })

export {
  importNotesData,
  deleteNotesData
}
