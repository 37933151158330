import React, { useState } from 'react';
import { useEffect } from 'react';
import { Dropdown, DropdownMenu } from 'reactstrap';
import ConfirmationModal from './ConfirmationModal';

function VideoEditMenu({ setImageMenu, deleteImage, hideEdit, styles, setStyles }) {
    const [menu, setMenu] = useState(false)
    const [openDeleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        setImageMenu(menu)
    }, [menu]);
  
  return (
    <div style={{display: 'flex', alignItems: 'center', gap:12}}>
        {hideEdit ? null : <Dropdown isOpen={menu} toggle={() => setMenu(false)} >
            <button type="button" onClick={() => { setMenu(true) }} className="action-button ab-icons">edit</button>
            <DropdownMenu end style={{ width: 300 }}>
                <p style={{fontWeight: 600, fontSize: 16, borderBottom: '1px solid #eee', padding: '0 10px 5px 10px'}}>Image Styles</p>
                <div style={{padding: '5px 15px 15px 15px'}}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='m-0'>Height</p>
                        <div className='d-flex'>
                        <input style={{width: 100}} type="number" value={styles.height} onChange={(e) => setStyles({
                            ...styles, 
                            height: e.target.value
                        })} />
                        <span className='ps-2'>px</span>
                        </div>
                    </div>
                    <div className='mt-2 d-flex justify-content-between align-items-center'>
                    <p className='m-0'>Object Fit</p>
                        <div className="d-flex">
                            <button type="button" onClick={() => {
                                setStyles({ ...styles,  objectFit: 'cover' });
                            }} className="toggle-button-left" style={{background: styles?.objectFit !== 'contain' ? 'var(--bs-primary)' : '#fff', color: styles?.objectFit === 'contain' ? 'var(--bs-primary)' : '#fff', height: 28, width: 62, fontSize: 12}}>Cover</button>
                            <button type="button" onClick={() => {
                                setStyles({ ...styles,  objectFit: 'contain' });
                            }} className="toggle-button-right" style={{background: styles?.objectFit === 'contain' ? 'var(--bs-primary)' : '#fff', color: styles?.objectFit !== 'contain' ? 'var(--bs-primary)' : '#fff', height: 28, width: 62, fontSize: 12}}>Contain</button>
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown> }
        
        <button type="button" onClick={() => { setDeleteModal(true) }} className="action-button delete-button ab-icons" style={{margin:0}}>trash</button>
        <ConfirmationModal title="Delete Video" onOk={async () => {
            await deleteImage()
            setDeleteModal(false)
        }} modal={openDeleteModal} closeModal={() => setDeleteModal(false)} />
    </div>
    
  );
}

export default VideoEditMenu;
