import React, { useState, useEffect, useMemo, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  Form,
  Label,
  Button,
  FormFeedback,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import { getAccounts } from "helpers/account_helper"
import Preloader from "components/Common/Preloader"
import "toastr/build/toastr.min.css"
import { formatAccountsData } from "utility/common_helper"
import Papa from 'papaparse';
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from 'react-router-dom';
import { importNotesData } from "helpers/chat_helper"

const NotesImport = () => {
  // meta title
  document.title = "Import Notes | " + process.env.REACT_APP_NAME

  const history = useHistory();

  const fileInputRef = useRef(null);

  const [showLoader, setShowLoader] = useState(false)
  const [csvFile, setCsvFile] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [accountID, setAccountID] = useState("")
  const [csvHeader, setCsvHeader] = useState([])
  const [csvData, setCsvData] = useState([])
  const [csvDataFiltered, setCsvDataFiltered] = useState([])

  const [isCsvParsed, setIsCsvParsed] = useState(false)
  const [idColumn, setIdColumn] = useState(null)
  const [commentColumn, setCommentColumn] = useState(null)
  const [dateColumn, setDateColumn] = useState(null)
  const [userIdColumn, setUserIdColumn] = useState(null)

  const [apiResponse, setApiResponse] = useState([])
  const [isImportRunning, setIsImportRunning] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      account_id: "",
      csvFile: null
    },
    validationSchema: Yup.object({
      account_id: Yup.string().required("Select Account is required"),
      csvFile: Yup.mixed().required("CSV File is required")
    }),
    onSubmit: async values => {
      try {
        handleParseCsv()
      } catch (error) {
        console.log("Error parsing CSV file: ", error)
      }
    },
  })

  const countUniqueValuesForKey = (arr, key) => {
    const uniqueValues = new Set();

    arr.forEach(obj => {
      if (obj.hasOwnProperty(key)) {
        uniqueValues.add(obj[key]);
      }
    });

    return uniqueValues.size;
  };

  const handleParseCsv = () => {
    setShowLoader(true)
    const header = [];
    const parsedData = [];
    
    Papa.parse(csvFile, {
      header: true,
      worker: false, // Disable worker due to CSP restrictions
      dynamicTyping: true,
      comments: '#',
      skipEmptyLines: 'greedy',
      download: true,
      chunk: function(results, parser) {
        // Process data in chunks
        parsedData.push(...results.data);
        
        // If this is the first chunk, set up the headers
        if (parsedData.length <= results.data.length && results.data.length > 0) {
          const sampleData = results.data.slice(0, 1000);
          
          // Filter out rows with no values from the sample
          const filteredSample = sampleData.filter(row =>
            Object.values(row).every(value =>
              value !== null && value !== undefined && value !== ''
            )
          );

          if (filteredSample.length > 0) {
            const tmp = Object.keys(filteredSample[0])
            for (const key of tmp) {
              header.push({
                name: key,
                mapped_column: null,
                mapped_column_error: null,
                settings: null,
                count: 0, // Will be updated in complete
                unique: countUniqueValuesForKey(filteredSample, key)
              });
            }
          }
        }
      },
      complete: () => {
        if (parsedData.length && header.length) {
          // Update counts for each column
          header.forEach(col => {
            col.count = parsedData.reduce((count, obj) => {
              if (obj.hasOwnProperty(col.name) && 
                  typeof obj[col.name] !== 'undefined' && 
                  obj[col.name] !== '' && 
                  obj[col.name] !== null) {
                return count + 1;
              }
              return count;
            }, 0);
          });

          // Filter the complete dataset
          const filteredData = parsedData.filter(row =>
            Object.values(row).every(value =>
              value !== null && value !== undefined && value !== ''
            )
          );

          setCsvHeader(header)
          setCsvData(parsedData)
          setCsvDataFiltered(filteredData)
          setIdColumn(header[0].name)
          setCommentColumn(header[1].name)
          setDateColumn(header[2].name)
          setUserIdColumn(header[3]?.name || '')
          setIsCsvParsed(true)
        }
        setShowLoader(false)
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setShowLoader(false);
      }
    });
  }

  const resetData = () => {
    setCsvData([])
    setCsvDataFiltered([])
    setCsvHeader([])
    setApiResponse([])
    setIsImportRunning(false)
    setIsCsvParsed(false)
    setIdColumn(null)
    setCommentColumn(null)
    setDateColumn(null)
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    setCsvFile(file)
    validation.setFieldValue("csvFile", file)
  }

  const fetchAccounts = async () => {
    const response = await getAccounts()
    setAccounts(formatAccountsData(response))
  }

  useEffect(() => {
    resetData()
  }, [csvFile, accountID])

  useEffect(() => {
    fetchAccounts()
  }, [])

  const handleImportData = async () => {
    try {
      setIsImportRunning(true)
      const formData = {
        account_id: accountID,
        id_column: idColumn,
        value_column: commentColumn,
        date_column: dateColumn,
        user_id_column: userIdColumn,
        csv_data: csvDataFiltered
      }
      await importNotesData(formData)
      resetData();
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      validation.setFieldValue("csvFile", null)
      setCsvFile(null);
      history.go(0); // Re-renders the current route without a hard reload
    } catch (error) {
      console.log("Error importing data: ", error)
    } finally {
      setIsImportRunning(false)
    }
  }


  return (
    <>
      <Preloader showLoader={showLoader} />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Notes Import" breadcrumbItem="Notes Import" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Upload CSV File</CardTitle>
                  <Form
                    onSubmit={validation.handleSubmit}
                    encType="multipart/form-data"
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">Select Account</Label>
                          <Select
                            id="account_id"
                            name="account_id"
                            isMulti={false}
                            onChange={e => {
                              setAccountID(e.value)
                              validation.setFieldValue("account_id", e.value)
                            }}
                            options={accounts}
                            className={`select2-selection ${validation.errors.account_id &&
                              validation.touched.account_id
                              ? "is-invalid"
                              : ""
                              }`}
                            required
                            inputProps={{ required: true }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>

                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="csvFile">CSV File</Label>
                          <Input
                            type="file"
                            id="csvFile"
                            name="csvFile"
                            onChange={handleFileChange}
                            accept=".xlsx, .xls, .csv"
                            required
                            ref={fileInputRef}
                          />
                        </div>
                      </Col>
                      <Col md={6}>

                      </Col>
                    </Row>

                    <Row className="justify-content-end mb-5">
                      <Col lg="12">
                        <Button type="submit" color="primary" disabled={isCsvParsed}>
                          Parse CSV
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              {isCsvParsed && (
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">CSV Overview</CardTitle>
                    <Row>
                      <Col md={12}>
                        <h6>Total Rows: {csvData.length}</h6>
                        <h5>Total Rows With Value: {csvDataFiltered.length}</h5>
                        <h5>Member ID Column: {idColumn}</h5>
                        <h5>Comment Column: {commentColumn}</h5>
                        <h5>Date Column: {dateColumn}</h5>
                        <h5>User ID Column: {userIdColumn}</h5>
                      </Col>
                      <Col lg="12" className="d-flex justify-content-center">
                        <Button type="button" color="primary" disabled={isImportRunning} onClick={handleImportData}>
                          {isImportRunning && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                          Import Data
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>

      </div>
    </>
  )
}

export default NotesImport
