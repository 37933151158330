import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import VideoEditMenu from './VideoEditMenu';

function VideoComponent({ content, addVideo, deleteContent, updateContent }) {
    const [url, setUrl] = useState('')
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    function extractLastId(url) {
        // Split the URL by '/'
        const parts = url.split('/');
        // Get the last part of the URL
        const lastPart = parts[parts.length - 1];
        // Extract the ID using a regular expression
        const id = lastPart.match(/[a-zA-Z0-9]+/);
        // Return the extracted ID
        return (id ? id[0] : null)
    }

  return (
    <div className='w-100 video-content-container'>
        <div className='video-edit-menu-button' style={{opacity: isMenuOpen === content.id ? 1 : ''}}>
            <VideoEditMenu  styles={content.styles} setStyles={(data) => {
                updateContent(content.id, 'styles', data)
            }} hideEdit deleteVideo={() => deleteContent(content.id)} setVideoMenu={(status) => setIsMenuOpen(status ? content.id : false)} />
        </div>
        {
            content.videoID || content.url ? <div className=''>
                <video style={{objectFit: 'cover', height: '100%', width: '100%'}} src={content.url || `https://cdn-std.droplr.net/files/acc_1253160/${content.videoID}`} preload="auto" controls poster={content.url || `https://cdn-std.droplr.net/previews/${content.videoID}.preview_medium.jpg`}></video>
            </div> :
            <div style={{border: '1px solid #ddd', borderRadius: 5, padding: 30}}>
                <p>Add Video URL</p>
                    <Input value={url} onChange={(e) => setUrl(e.target.value)} type="text" />
                <div className='d-flex justify-content-center w-100'>
                    <Button type="button" className='mt-3' onClick={async () => addVideo(url.includes('agentbook.d.pr') ? extractLastId(url) : url, url.includes('agentbook.d.pr'))}>Add Video</Button>
                </div>
            </div>
        }
    </div>
  );
}

export default VideoComponent;
