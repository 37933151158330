import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
dayjs.extend(customParseFormat)

export function getSelectedOptionNestedSelect(dataArray, valueToSearch) {
  const results = []
  let valueToSearchTmp = valueToSearch

  const isSingleSearch = !Array.isArray(valueToSearchTmp)

  if (isSingleSearch) {
    valueToSearchTmp = [valueToSearchTmp]
  }

  dataArray.forEach(dataItem => {
    dataItem.options.forEach(option => {
      if (valueToSearchTmp.includes(option.value)) {
        results.push(option)
      }
    })
  })

  if (isSingleSearch) {
    return results[0] || ""
  }

  return results
}

export function isValidDateTime(dateStr, formats) {
  if (!formats.length) {
    return false
  }
  const date = dayjs(dateStr, [...formats], true)
  return date.isValid()
}

export function splitIntoBatches(arr, batchSize) {
  const batches = [];
  
  for (let i = 0; i < arr.length; i += batchSize) {
      batches.push(arr.slice(i, i + batchSize));
  }
  
  return batches;
}

export function orderKeysAlphabetically(obj) {
  if (Array.isArray(obj)) {
      return obj.map(orderKeysAlphabetically);
  } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj)
          .sort()
          .reduce((acc, key) => {
              acc[key] = orderKeysAlphabetically(obj[key]);
              return acc;
          }, {});
  }
  return obj;
}

export function convertToString(value) {
    if (Array.isArray(value)) {
        return value.map(item => String(item));
    }
    return String(value);
}

export function formatAccountsData(data) {
  let tmp = []
  for (let row of data) {
    let label = row?.name || ""
    let organization_type = row?.organization_type || null
    let user_name =
      (row?.users[0]?.first_name || "") +
      " " +
      (row?.users[0]?.last_name || "")
    if (organization_type == "agent" && row.title) {
      label = row.title.name
    } else if (organization_type == "brokerage" && row.brokerage) {
      label = row.brokerage.name
    } else if (organization_type == "board" && row.board) {
      label = row.board.name
    } else if (organization_type == "service_provider" && row.service_type) {
      label = row.service_type.name
    }
    label = row.id + " " + label + " (" + user_name + ")"
    if (organization_type) {
      label += " [" + organization_type.toUpperCase() + "]"
    }
    tmp.push({ label: label, value: row.id, ...row })
  }
  return tmp
}

export function isNumeric(value) {
  return !isNaN(value) && !isNaN(parseFloat(value));
}

export function isValidUrl(value) {
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  return regex.test(value);
}

export async function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    // Base case: return non-object values as is
    return obj;
  }

  if (obj instanceof File) {
    // Handle File objects
    const file = obj;
    return new File([file.slice()], file.name, { type: file.type });
  }

  if (Array.isArray(obj)) {
    // Handle arrays
    const newArray = [];
    for (const item of obj) {
      newArray.push(await deepCopy(item));
    }
    return newArray;
  }

  // Handle objects
  const newObj = {};
  for (const key in obj) {
    newObj[key] = await deepCopy(obj[key]);
  }
  return newObj;
}
