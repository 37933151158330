import React  from "react"
import { withRouter } from "react-router-dom"
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

const ConfirmationModal = props => {
    return (
        <Modal isOpen={props.modal} toggle={() => {}} size="md" centered fade={false}>
            <ModalHeader toggle={props.closeModal}>{props.title}</ModalHeader>
            <ModalBody className="add-category-modal-container">
                <p className="ab-icons color-danger text-center" style={{color: 'var(--bs-danger)', fontSize: 30, marginBottom: 20}}>trash</p>
            <p className="mb-1 text-center">Are you sure you want to delete this item?</p>
            <p className="mb-1 text-center">This action cannot be undone</p>
            </ModalBody>
            <ModalFooter>
            <Button onClick={() => props.closeModal()} color="light">
                Cancel
                </Button>
                <Button onClick={() => props.onOk(props.modal)}  color="danger">
                Delete
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default withRouter(ConfirmationModal)
