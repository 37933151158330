import { del, get, post, postFormData } from "./api_helper"
import * as url from "./url_helper"

const getTutorialsCategory = (params = {}) => get(url.TUTORIALS_CATEGORY_LIST, params)
const createUpdateTutorialsCategory = data => post(url.TUTORIALS_CATEGORY_CREATE_UPDATE, data)
const deleteTutorialsCategory = id => del(url.TUTORIALS_CATEGORY_DELETE + id)
const sortOrderUpdateTutorialsCategory = data => post(url.TUTORIALS_CATEGORY_SORT_ORDER_UPDATE, data)

const getTutorials = (params = {}) => get(url.TUTORIALS_LIST, params)
const createUpdateTutorials = data => postFormData(url.TUTORIALS_CREATE_UPDATE, data)
const deleteTutorials = id => del(url.TUTORIALS_DELETE + id)
const sortOrderUpdateTutorials = data => post(url.TUTORIALS_SORT_ORDER_UPDATE, data)
const statusUpdateTutorials = data => post(url.TUTORIALS_STATUS_UPDATE, data)

export { getTutorialsCategory, createUpdateTutorialsCategory, deleteTutorialsCategory, sortOrderUpdateTutorialsCategory, getTutorials, createUpdateTutorials, deleteTutorials, sortOrderUpdateTutorials, statusUpdateTutorials }
