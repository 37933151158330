import React, { useState, useEffect, useMemo, useRef } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    Form,
    Label,
    Button,
    FormFeedback,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    Table,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import { importAccreditationsData, validateImportData } from "helpers/import"
import { getAccounts } from "helpers/account_helper"
import Preloader from "components/Common/Preloader"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { formatAccountsData } from "utility/common_helper"
import Papa from 'papaparse';
import Breadcrumbs from "components/Common/Breadcrumb"
import { titleCase } from "helpers/common_helper"
import { getLanguage } from "helpers/languages_helper"
import { getAccr } from "helpers/accr_helper"
import { useHistory } from 'react-router-dom';

const AccreditationsImport = () => {
    // meta title
    document.title = "Import Accreditations | " + process.env.REACT_APP_NAME

    const history = useHistory();

    const fileInputRef = useRef(null);

    const [showLoader, setShowLoader] = useState(false)
    const [csvFile, setCsvFile] = useState(null)
    const [accounts, setAccounts] = useState([])
    const [accountID, setAccountID] = useState("")
    const [csvHeader, setCsvHeader] = useState([])
    const [csvData, setCsvData] = useState([])
    const [csvDataFiltered, setCsvDataFiltered] = useState([])
    const [unMappedData, setUnMappedData] = useState([])

    const [isCsvParsed, setIsCsvParsed] = useState(false)
    const [idColumn, setIdColumn] = useState(null)
    const [valueColumn, setValueColumn] = useState(null)

    const [apiResponse, setApiResponse] = useState([])
    const [isImportRunning, setIsImportRunning] = useState(false)

    const [languages, setLanguages] = useState([])
    const [accreditations, setAccreditations] = useState([])


    // Add a new state variable
    const [allItemsMapped, setAllItemsMapped] = useState(false);

    const [mappedItems, setMappedItems] = useState({});

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            account_id: "",
            csvFile: null
        },
        validationSchema: Yup.object({
            account_id: Yup.string().required("Select Account is required"),
            csvFile: Yup.mixed().required("CSV File is required")
        }),
        onSubmit: async values => {
            try {
                handleParseCsv()
            } catch (error) {
                console.log("Error parsing CSV file: ", error)
            }
        },
    })

    const countUniqueValuesForKey = (arr, key) => {
        const uniqueValues = new Set();

        arr.forEach(obj => {
            if (obj.hasOwnProperty(key)) {
                uniqueValues.add(obj[key]);
            }
        });

        return uniqueValues.size;
    };

    const handleParseCsv = () => {
        setShowLoader(true)
        const header = [];
        const parsedData = [];
        let ind = 0;

        Papa.parse(csvFile, {
            header: true,
            worker: false,
            dynamicTyping: true,
            comments: '#',
            skipEmptyLines: 'greedy',
            download: true,
            step: (row) => {
                parsedData.push({
                    // parse_csv_id: ind,
                    ...row.data
                });

                ind += 1;
            },
            complete: () => {
                if (parsedData.length) {
                    // Filter out rows with no values
                    const filteredData = parsedData.filter(row =>
                        Object.values(row).every(value =>
                            value !== null && value !== undefined && value !== ''
                        )
                    );

                    const tmp = Object.keys(filteredData[0])
                    for (const key of tmp) {
                        header.push({
                            name: key,
                            mapped_column: null,
                            mapped_column_error: null,
                            settings: null,
                            count: filteredData.reduce((count, obj) => {
                                if (obj.hasOwnProperty(key) && typeof obj[key] !== 'undefined' && obj[key] !== '' && obj[key] !== null) {
                                    return count + 1;
                                }
                                return count;
                            }, 0),
                            unique: countUniqueValuesForKey(filteredData, key)
                        });
                    }
                    setCsvHeader(header)
                    setCsvData(parsedData)
                    setCsvDataFiltered(filteredData)
                    setIdColumn(header[0].name)
                    setValueColumn(header[1].name)
                    setIsCsvParsed(true)
                }
                setShowLoader(false)
            },
        });
    }

    const resetData = () => {
        setCsvData([])
        setCsvDataFiltered([])
        setCsvHeader([])
        setApiResponse([])
        setIsImportRunning(false)
        setIsCsvParsed(false)
        setIdColumn(null)
        setValueColumn(null)
    }

    const handleFileChange = event => {
        const file = event.target.files[0]
        setCsvFile(file)
        validation.setFieldValue("csvFile", file)
    }

    const fetchAccounts = async () => {
        const response = await getAccounts()
        setAccounts(formatAccountsData(response))
    }

    useEffect(() => {
        resetData()
    }, [csvFile, accountID])

    const fetchLanguages = async () => {
        const response = await getLanguage()
        setLanguages(response.map(lang => ({
            label: lang.name,
            value: lang.id
        })))
    }

    const fetchAccreditations = async () => {
        const response = await getAccr()
        setAccreditations(response.map(lang => ({
            label: lang.title,
            value: lang.id
        })))
    }

    useEffect(() => {
        fetchAccounts()
        fetchLanguages()
        fetchAccreditations()
    }, [])

    const handleImportData = async (ignoreValidation = false) => {
        try {
            setIsImportRunning(true)
            setUnMappedData([]);
            const formData = {
                type: "accreditation",
                account_id: accountID,
                id_column: idColumn,
                value_column: valueColumn,
                csv_data: csvDataFiltered
            }

            if (!ignoreValidation) {
                const response = await validateImportData(formData)

                if (response.data.length === 0) {
                    await importAccreditationsData(formData)
                    resetData();
                    if (fileInputRef.current) {
                        fileInputRef.current.value = null;
                    }
                    validation.setFieldValue("csvFile", null)
                    setCsvFile(null);
                    history.go(0); // Re-renders the current route without a hard reload
                } else {
                    setUnMappedData(response.data)
                }
            } else {
                await importAccreditationsData(formData)
                resetData();
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
                validation.setFieldValue("csvFile", null)
                setCsvFile(null);
                history.go(0); // Re-renders the current route without a hard reload
            }

        } catch (error) {
            console.log("Error importing data: ", error)
        } finally {
            setIsImportRunning(false)
        }
    }

    const handleAccreditationMapping = (value, selectedOption) => {
        setCsvDataFiltered(prevData =>
            prevData.map(item =>
                item[valueColumn].toLowerCase() === value
                    ? { ...item, mapped_id: selectedOption.value }
                    : item
            )
        );

        setMappedItems(prev => ({
            ...prev,
            [value]: selectedOption.value
        }));

        setAllItemsMapped(Object.keys(mappedItems).length + 1 === unMappedData.length);
    };

    return (
        <>
            <Preloader showLoader={showLoader} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Accreditations Import" breadcrumbItem="Accreditations Import" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Upload CSV File</CardTitle>
                                    <Form
                                        onSubmit={validation.handleSubmit}
                                        encType="multipart/form-data"
                                    >
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Select Account</Label>
                                                    <Select
                                                        id="account_id"
                                                        name="account_id"
                                                        isMulti={false}
                                                        onChange={e => {
                                                            setAccountID(e.value)
                                                            validation.setFieldValue("account_id", e.value)
                                                        }}
                                                        options={accounts}
                                                        className={`select2-selection ${validation.errors.account_id &&
                                                            validation.touched.account_id
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        required
                                                        inputProps={{ required: true }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="csvFile">CSV File</Label>
                                                    <Input
                                                        type="file"
                                                        id="csvFile"
                                                        name="csvFile"
                                                        onChange={handleFileChange}
                                                        accept=".xlsx, .xls, .csv"
                                                        required
                                                        ref={fileInputRef}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>

                                            </Col>
                                        </Row>

                                        <Row className="justify-content-end mb-5">
                                            <Col lg="12">
                                                <Button type="submit" color="primary" disabled={isCsvParsed}>
                                                    Parse CSV
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            {isCsvParsed && (
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">CSV Overview</CardTitle>
                                        <Row>
                                            <Col md={12}>
                                                <h6>Total Rows: {csvData.length}</h6>
                                                <h5>Total Rows With Value: {csvDataFiltered.length}</h5>
                                                <h5>Member ID Column: {idColumn}</h5>
                                                <h5>Value Column: {valueColumn}</h5>
                                            </Col>
                                            {unMappedData.length === 0 && (
                                                <Col lg="12" className="d-flex justify-content-center">
                                                    <Button type="button" color="primary" disabled={isImportRunning} onClick={() => { handleImportData() }}>
                                                        {isImportRunning && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                                                        Import Data
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>

                                        {unMappedData.length > 0 && (
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mt-4">
                                                        <h5>Language Mapping</h5>
                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th width="60%">Value</th>
                                                                    <th width="40%">Map With</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {unMappedData.map((item, index) => (
                                                                    <tr key={index} className={mappedItems[item] ? 'table-success' : ''}>
                                                                        <td>{titleCase(item)}</td>
                                                                        <td>
                                                                            <Select
                                                                                options={accreditations}
                                                                                onChange={selectedOption => handleAccreditationMapping(item, selectedOption)}
                                                                                value={mappedItems[item] ?
                                                                                    accreditations.find(lang => lang.id === mappedItems[item]) : null
                                                                                }
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                                <Col lg="12" className="d-flex justify-content-center mt-3">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        disabled={!allItemsMapped || isImportRunning}
                                                        onClick={() => { handleImportData(true) }}
                                                    >
                                                        {isImportRunning && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                                                        Import Data
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default AccreditationsImport
