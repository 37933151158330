import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
const postJwtLogin = data => post(url.JWT_LOGIN, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const accessCheckFn = () => get(url.ACCESS_CHECK)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  postJwtProfile,
  accessCheckFn,
}
