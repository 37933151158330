import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    Form,
    Label,
    Button,
    FormFeedback,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import { getAccounts } from "helpers/account_helper"
import Preloader from "components/Common/Preloader"
import "toastr/build/toastr.min.css"
import { formatAccountsData } from "utility/common_helper"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from 'react-router-dom';
import SampleDirectorsCommittees from "assets/csv/sample-directors-committees.zip"
import { importDirectorsCommitteesData } from "helpers/directors_committees_helper"

const DirectorsCommitteesImport = () => {
    // meta title
    document.title = "Board Of Directors & Committees | " + process.env.REACT_APP_NAME

    const history = useHistory();

    const [showLoader, setShowLoader] = useState(false)

    const [accounts, setAccounts] = useState([])
    const [accountID, setAccountID] = useState("")

    const [isImportRunning, setIsImportRunning] = useState(false)

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            account_id: "",
            directors_committees_csv_file: null,
            contacts_csv_file: null,
        },
        validationSchema: Yup.object({
            account_id: Yup.string().required("Select Account is required"),
            directors_committees_csv_file: Yup.mixed().required("Directors & Committees CSV File is required"),
            contacts_csv_file: Yup.mixed().required("Contacts CSV File is required")
        }),
        onSubmit: async values => {
            try {
                try {
                    setIsImportRunning(true)
                    const formData = {
                        account_id: accountID,
                        directors_committees_csv_file: values.directors_committees_csv_file,
                        contacts_csv_file: values.contacts_csv_file,
                    }
                    await importDirectorsCommitteesData(formData)
                    validation.setFieldValue("directors_committees_csv_file", null)
                    validation.setFieldValue("contacts_csv_file", null)
                    setTimeout(() => {
                        history.go(0); // Re-renders the current route without a hard reload
                    }, 500);
                } catch (error) {
                    console.log("Error importing data: ", error)
                } finally {
                    setIsImportRunning(false)
                }
            } catch (error) {
                console.log("Error parsing CSV file: ", error)
            }
        },
    })

    const handleDirectorsCommitteesFileChange = event => {
        const file = event.target.files[0]
        validation.setFieldValue("directors_committees_csv_file", file)
    }

    const handleContactsFileChange = event => {
        const file = event.target.files[0]
        validation.setFieldValue("contacts_csv_file", file)
    }

    const fetchAccounts = async () => {
        const response = await getAccounts()
        setAccounts(formatAccountsData(response))
    }

    useEffect(() => {
        fetchAccounts()
    }, [])

    return (
        <>
            <Preloader showLoader={showLoader} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Board Of Directors & Committees" breadcrumbItem="Board Of Directors & Committees" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Upload CSV File</CardTitle>
                                    <Form
                                        onSubmit={validation.handleSubmit}
                                        encType="multipart/form-data"
                                    >
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Select Account</Label>
                                                    <Select
                                                        id="account_id"
                                                        name="account_id"
                                                        isMulti={false}
                                                        onChange={e => {
                                                            setAccountID(e.value)
                                                            validation.setFieldValue("account_id", e.value)
                                                        }}
                                                        options={accounts}
                                                        className={`select2-selection ${validation.errors.account_id &&
                                                            validation.touched.account_id
                                                            ? "is-invalid"
                                                            : ""
                                                            }`}
                                                        required
                                                        inputProps={{ required: true }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="pull-right">
                                                    <a
                                                        href={SampleDirectorsCommittees}
                                                        download="SampleDirectorsCommittees.zip"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn btn-info btn-sm"
                                                        >
                                                            <i className="mdi mdi-download font-size-16 align-middle me-2"></i>{" "}
                                                            Example Template
                                                        </button>
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="directors_committees_csv_file">Directors & Committees CSV File</Label>
                                                    <Input
                                                        type="file"
                                                        id="directors_committees_csv_file"
                                                        name="directors_committees_csv_file"
                                                        onChange={handleDirectorsCommitteesFileChange}
                                                        accept=".xlsx, .xls, .csv"
                                                        required
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="contacts_csv_file">Contacts CSV File</Label>
                                                    <Input
                                                        type="file"
                                                        id="contacts_csv_file"
                                                        name="contacts_csv_file"
                                                        onChange={handleContactsFileChange}
                                                        accept=".xlsx, .xls, .csv"
                                                        required
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-end mb-5">
                                            <Col lg="12">
                                                <Button type="submit" color="primary" disabled={isImportRunning}>
                                                    {isImportRunning && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}
                                                    Import Events
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default DirectorsCommitteesImport
