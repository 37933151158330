import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CHECK_ACCESS, LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  accessCheckSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { postJwtLogin, accessCheckFn } from "../../../helpers/auth_helper"
import { removeUserCookie, setUserCookie } from "helpers/storage"

function* checkAccess() {
  try {
    const response = yield call(accessCheckFn)
    yield put(accessCheckSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    })
    setUserCookie(response);
    yield put(loginSuccess(response))
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    removeUserCookie();
    yield put(logoutUserSuccess())
    if (history) {
      history.push("/login")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeLatest(CHECK_ACCESS, checkAccess)
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeLatest(LOGOUT_USER, logoutUser)
}

export default authSaga
