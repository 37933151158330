import { getUserCookie } from "helpers/storage"
import {
  CHECK_ACCESS_SUCCESS,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  is_user_logged_in: !!getUserCookie(),
  isAccessible: false,
  isAccessChecked: false,
  user: getUserCookie(),
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_ACCESS_SUCCESS:
      state = {
        ...state,
        isAccessible: action.payload.is_accessible,
        isAccessChecked: true,
      }
      break
    case LOGIN_USER:
      state = { ...state, loading: true }
      break
    case LOGIN_SUCCESS:
      state = { ...state, is_user_logged_in: true, loading: false, user: action.payload }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, error: "", loading: false, is_user_logged_in: false }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
