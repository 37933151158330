import { del, get, post, postFormData } from "./api_helper"
import * as url from "./url_helper"

// Post Give Aways
const getPosts = () => get(url.GET_POSTS)
const createUpdatePost = data => postFormData(url.CREATE_UPDATE_POST, data)
const deletePost = data => del(url.DELETE_POST, data)

// Raffle Items
const getRaffleItems = () => get(url.GET_RAFFLE_ITEMS)
const createUpdateRaffleItem = data =>
  postFormData(url.CREATE_UPDATE_RAFFLE_ITEM, data)
const updateRaffleItemData = data => post(url.UPDATE_RAFFLE_ITEM_DATA, data)
const deleteRaffleItem = data => del(url.DELETE_RAFFLE_ITEM, data)

const getSsoList = () => get(url.GET_SSO_LIST)

// Cities
const getCities = (params = {}) => get(url.GET_CITIES, params)

export {
  getPosts,
  createUpdatePost,
  deletePost,
  getRaffleItems,
  createUpdateRaffleItem,
  updateRaffleItemData,
  deleteRaffleItem,
  getSsoList,
  getCities,
}
